.home {
  img {
    opacity: 1;
    transition: filter 1s ease opacity 1s ease;
  }

  img.lq {
    filter: blur(var(--img-load-blur-amount)) brightness(1.25) contrast(0.95) !important;
    opacity: 0.6;
  }

  img.hero {
    width: 100%;
    min-height: calc(min(30vw, 660px));
    position: relative;
    filter: blur(0px) brightness(1.25) contrast(0.95);
  }
  
  .img-filter {
    background-image: linear-gradient(0deg, rgba(255, 225, 190, 0), var(--window-orange));
    opacity: 0.25;
    position: absolute;
    width: var(--page-width);
    max-width: var(--page-max-width);
    height: 15vw;
    z-index: 2;
  }

  [data-expanded=false] {
    height: 48pt;
    padding: 12px;
    overflow: hidden;
    cursor: pointer;

    p.center span {
      background-color: var(--window-pink);
      box-shadow: var(--window-pink) 0 0 0 3px;
      z-index: 1;
    }

    p.center span:hover {
      box-shadow: var(--window-pink) 0 0 0 12px;
    }
  }

  .schedule {
    table {
      margin-top: 18px;
    }
    margin-bottom: 72px;
  }

  .ceremony-detail {
    padding: 24px 0;

    h4:not(:first-child) {
      margin-top: 24px;
    }

    p {
      margin-bottom: 1.5rem;
    }

    ol {
      padding-left: 1rem;
    }

    li {
      list-style-type: none;
      margin: 12px 0;
    }

    .subtle {
      letter-spacing: normal;
      margin-left: 24px;
      display: block;
    }
  }
}