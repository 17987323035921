@-webkit-keyframes infiniteRotate {    
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
/* Standard syntax */
@keyframes infiniteRotate {  
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// GLOBAL STYLES

a:visited, a:link{
  color: inherit;
}

@media only screen and (min-width: 641px) {
  a:hover {
    box-shadow: var(--window-pink) 0 0 0 12px;
    background-color: var(--window-pink);
  }
}

main {
  min-height: 50vh;
}

h1 {
  font-family: 'timberline', -apple-system, BlinkMacSystemFont;
}

h2, h3, h4 {
  font-family: 'kepler-std-display', -apple-system, BlinkMacSystemFont, serif;
}

button, option, select {
  font-family: 'raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
}

summary h3, summary h4 {
  display: inline;
}

img {
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}

img.with-modal:hover {
  cursor: pointer;
}

.signature {
  font-size: 64pt;
  display: block;
  text-align: center;
  margin: 0;
  padding-right: 3%;
}

.center {
  text-align: center;
}

.ellipsis {
  text-align: center;
  font-kerning: none;
  letter-spacing: 12px;
}

.App {
  margin: auto;
  width: var(--page-width);
  min-width: var(--page-min-width);
  max-width: var(--page-max-width);
  transition: opacity 1.5s ease-in;
}

.tooltip .arrow{
  transition: none;
}

.App.show {
  opacity: 1;
}

.App.hidden {
  opacity: 0;
}

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading svg {
  padding: 0;
  position: relative;
  -webkit-animation: infiniteRotate 2s linear infinite;
  animation: infiniteRotate 2s linear infinite;
}

.listItemHead {
  font-weight: bold;
}

.lead-in {
  margin-top: 96px;
  margin-bottom: 72px;
}

span.no-break {
  display: inline-block;
}

.tight {
  margin: 0;
}

p.even-margin {
  margin: 18px 0;
}

.text-content {
  box-sizing: border-box;
  margin: 12px 0px 48px;
  padding: 18px 18px;
  position: relative;
  
  p {
    margin: 0 0 28px;
  }

  .ellipsis {
    margin-bottom: 0;
  }
}

.maz .label { 
  background-color: var(--window-orange-opaque); 
  box-shadow: var(--window-orange-opaque) 0 0 6px -2px;
}
.aaron .label { 
  background-color: var(--window-pink-opaque); 
  box-shadow: var(--window-pink-opaque) 0 0 6px -2px;
}

.subtle {
  color: var(--main-grey);
  letter-spacing: 2pt;
  font-size: 12pt;
}

button.logout {
  background-color: #1085d3;
  color: var(--main-white);
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: var(--main-black) 0 1px 6px -2px;
  margin-top: 6px;

  &:hover {
    filter: brightness(1.15);
  }
  &:active {
    transition: filter 0.1s linear;
    filter: brightness(0.9);
  }
}

button.auth {
  display: inline-block;
  margin: 0 auto;
  border: none;
  background: none;
  &:hover {
    opacity: 0.9;
    filter: brightness(0.7) contrast(1.5);
  }
}

button.g-login {
  width: 260px;
  padding: 0;

  img {
    height: 64px;
    width: 260px;
  }
}

button.fb-login {
  background-color: #1877F2;
  color: white;
  height: 57px;
  width: 253px;
  box-sizing: border-box;
  padding: 6px 12px;
  text-align: left;
  border-radius: 2.5px;

  img {
    max-height: 80%;
  }
  span {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.1rem;
    margin-left: 12px;
  }
}

.countdownComponent {
  padding: 48px 0 64px;
  font-size: 32pt;
  font-family: 'timberline';
  border-radius: 50%;
  margin: 84px 0;
  
  :first-child {
    font-family: 'kepler-std-display';
    margin-bottom: 0px;
  }

  :last-child {
    margin-top: 28px;
  }
  
  p {
    margin: 0;
    line-height: 1.4;
  }

  span {
    font-family: 'timberline' !important;
    font-size: 84pt;
    transform: translate(-9px, 12px);
    position: relative;
    display: inline-block;
  }
}

main.registry {
  text-align: center;
  padding-top: 24px;
  p {line-height: 3;}
  :nth-child(2) {
    font-size: 1.5rem;
  }
}

@-moz-keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}

@-webkit-keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}

@keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}

aside.img-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.151);
  width: 100vw;
  height: 100vh;

  -webkit-animation: fadeIn 500ms;
  -moz-animation: fadeIn 500ms;
  animation: fadeIn 500ms;

  img {
    position: relative;
    object-fit: contain;
    max-width: 90vw;
    max-height: 80vh;
    margin: 10vh auto;
    display: block;
    z-index: 11;

    &.modal-shadow {
      position: absolute;
      top: 40px; left: 0; right: 0;
      z-index: 9;
      transform: scaleY(1.03);
      filter: brightness(0.1) blur(60px);
      clip-path: none;
    }

  }

  div.controls {
    position: fixed;
    width: 100%;
    height: 10vh;
    z-index: 12;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: 2.5rem;
    color: var(--main-white);
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  span.action-button {
    margin: 0 18px;
    padding-top: 1.5vh;
    display: inline-block;

    * {
      background-color: var(--main-grey);
      box-shadow: var(--main-grey) 0 0 9px -3px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .close-control {
    position: fixed;
    top: 0;
    right: 0;

    .action-button {
      z-index: 12;
      font-size: 2.5rem;
      color: var(--main-white);
    }
  }
}

section.login-guard {
  text-align: center;

  p.lead {
    font-weight: 700;
    font-size: 1.35rem;
  }

  p#disclaimer {
    display: block;
    font-size: 1.05rem;
    margin: 18px auto;
    width: 60%;
    max-width: 450px;
  }
}

#privacy-policy {
  h1 {
    font-family: 'raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 48px;
    padding-bottom: 6px;
  }
  h2 {
    margin-bottom: 24px;
  }
  p#privacyDateModified {
    font-size: 1.3rem;
    font-style: italic;
  }
}

footer a { text-decoration: underline; }
