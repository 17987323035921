.flex-border-container {
  display: flex;
  align-items: center;  
  justify-content: space-between;
  text-align: center;
  margin: 54px 0 0;
  width: 100%;
  line-height: 2;

  .flex-border {
    flex-grow: 1;
    height: 1px;
    background-color: var(--main-black);

    &.fb-left {margin-right: 18px;}
    &.fb-right {margin-left: 18px;}
  }

  span {
    font-family: 'kepler-std-display';
    h3 { font-size: 2.75rem; }
    h4 { font-size: 2.25rem; }
  }

  &.added-space {
    margin: 72px 0px;
  }

  @media screen and (min-width: 640px) {
    div.flex-border.fb-hide {display: none;}
  }
}