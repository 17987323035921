.photos-tab {

  img {
    opacity: 1;
    transition: filter 1s ease opacity 1s ease;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img.lq {
    filter: blur(var(--img-load-blur-amount)) brightness(1.25) contrast(0.95) !important;
    opacity: 0.6;
  }

  #grid, #grid1, #grid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: calc(min(35vw, 350px));

    .i02 {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }

    .i04 {
      grid-column: 2 / 4;
      grid-row: 3 / 5;
    }

    .i08 {
      grid-column: 2 / 4;
      grid-row: 7 / 9;
    }

    .i14 {
      grid-row: 6 / 7;
      grid-column: 1 / 4;
    }

    .i18 {
      grid-column: 1 / 4;
      grid-row: 10 / 11;
    }

    .i29 {
      grid-column: 1 / 3;
      grid-row: 14 / 16;
    }

    .i24 {
      grid-column: 1 / 4;
    }
  }

  #grid2 {
    #e0013 {
      grid-column: 1 / 4;
      grid-row: 1 / 2;
    }

    #e0017 {
      grid-column: 2 / 4;
      grid-row: 4 / 6;
      object-position: 50% 20%;
    }

    #e0018 {
      grid-column: 1 / 4;
    }

  }

  #grid1 {
    #w0005 {
      grid-column: 1 / 3;
    }

    #w0008 {
      grid-row: 3 / 5;
      grid-column: 2 / 4;
    }

    #w0009 {
      grid-row: 5 / 7;
      grid-column: 1 / 3;
    }

    #w0010 {
      grid-row: 7 / 8;
      grid-column: 1 / 4;
    }

    #w0016 {
      grid-row: 10 / 11;
      grid-column: 2 / 4;
      object-position: 50% 35%;
    }

    #w0023 {
      grid-row: 11 / 12;
      grid-column: 1 / 3;
      object-position: 50% 35%;
    }

    #w0036 {
      grid-row: 14 / 16;
      grid-column: 1 / 3;
      object-position: 50% 35%;
    }

    #w0045 {
      grid-column: 1 / 4;
    }
  }

  .photo-expander {
    margin-bottom: 24px;
  }

  .photo-expander > span {
    display: inline-flex;
    align-items: center;
  }

  .photo-expander > span:hover {
    cursor: pointer;
  }

  .photo-expander .heading-title {
    font-size: 1.6rem;
    line-height: 1.2;
  }
  
  img.expand-section-icon {
    display: inline;
    width: 1.7rem;
    margin-right: 14px;
    clip-path: unset;
  }

  section.closed {
    display: none;
  }

  .expand-section-icon.open {
    transform: rotate(45deg);
  }

}