header {
  text-align: center;
  display: block;
  margin-bottom: 28px;

  .nav-interaction-svg {
    max-width: 35px;
  }
  
  h1 {
    font-family: 'leander-script-pro';
    font-feature-settings: "salt" 1;
    font-size: 96px;
    line-height: 1;
    margin: 72px 0 24px;
    text-shadow: 0 0 18px var(--window-orange);

    img {
      width: 75%;
    }
  }

  div {
    &#left {margin-right: 18px;}
    &#right {margin-left: 18px;}
  }

  div.mobile-controls {
    display: none;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0;
    width: 100%;
    line-height: 2;

    .nav-padding {
      flex-grow: 2;
      height: 1px;
      background-color: var(--main-black);  
    }

    li {
      padding-top: 4px;
      list-style-type: none;
      display: inline-block;
    }

    a {
      color: var(--main-black);
      padding: 3px;
      margin: 3px 12px;
      text-decoration: none;
      // font-size: 14pt;
      
      &:hover {
        background-color: var(--window-pink);
      }

      &.disabled {
        opacity: 0.5;
        &:hover {
          background: none;
          box-shadow: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {

  header {

    div.mobile-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
      margin: 12px 0;
      width: 100%;
      font-size: 24pt;
    }
    
    .mobile-controls .nav-padding {
      flex-grow: 1;
      height: 1px;
      background-color: var(--main-black);  
    }
    
    #navExpandIcon:hover, #navExpandSlideIcon:hover {
      cursor: pointer;
    }
    
    .mobile-controls.closed #navExpandIcon {
      transform: rotate(0deg);
    }
    
    .mobile-controls.open #navExpandIcon {
      transform: rotate(45deg);
    }

    .mobile-controls.closed #navExpandSlideIcon {
      transform: rotate(0deg);
      right: -36px;
    }
    
    .mobile-controls.open #navExpandSlideIcon {
      transform: rotate(45deg);
    }

    #navExpandSlideIcon {
      position: fixed;
      top: 12px;
      right: 12px;
      z-index: 22;
    }
    
    nav {
      position: fixed;
      transition: opacity 1s;
    }
    
    nav .nav-padding {
      display: none;
    }
  
    nav span * {
      display: block;
      width: 100vw;
      background-color: whitesmoke;
      box-shadow: whitesmoke 0 0 12px -3px;
    }

    nav a:hover {
      background-color: #f5f5f5;
      box-shadow: var(--window-pink) 0 0 12px 0px;
      z-index: 25;
    }

    nav.open {
      z-index: 10;
      left: 0;
      top: 0;
      opacity: 1;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(0deg, rgba(220,220,220, 0.85), rgba(235, 235, 235, 0.85));
      
      a {
        padding: 6px 0;
        margin: 12px 0;
        text-decoration: none;
      }
    }

    nav.closed {
      transition: opacity 0s;
      opacity: 0;
      transform: translateX(100%);
    }

  }

}

@media only screen and (min-width: 641px) {

  span.tablet-row {
    display: inline-block;
    width: max-content;
  }
  
  div.nav-inner-contents {
    display: inline-block;
    line-height: 3;
  }

}

@media only screen and (max-width: 1105px) {

  div.nav-inner-contents {
    display: inline-block;
    width: min-content;
    line-height: 3;
  }

}