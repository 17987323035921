footer {
  --footer-height: 90px;

  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 90px;
  min-height: var(--footer-height);
  background-color: var(--main-black);
  color: var(--main-white);
  box-shadow: rgb(45,45,45) 0 18px 26px -18px inset;
}

footer .content {
  margin: auto;
  padding: 18px 0;
  width: var(--page-width);
  max-width: var(--page-max-width);
  min-width: var(--page-min-width);
  min-height: var(--footer-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12pt;
}

footer span {
  margin: 6px 0;
}

@media only screen and (max-width: 640px) {
  footer .content {
    display: block;
    text-align: center;
    * {
      display: block;
      width: 100%;
    }
  }
}