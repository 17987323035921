@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@200;400;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Bitter:wght@200;400;700&family=Great+Vibes&display=swap'); */

@import url("https://use.typekit.net/kbp0dbu.css");
/*
adobe-caslon-pro
leander-script-pro
adorn-catchwords
adorn-garland
adorn-ornaments
kepler-std-display
timberline
*/

:root {
  --main-black: rgb(60, 60, 60);
  --main-grey: rgb(90, 90, 90);
  --main-grey-trans: rgba(90, 90, 90, 0.5);
  --main-white: snow;
  --window-pink: hsla(120, 45%, 35%, 0.2);
  --window-pink-opaque: rgb(70, 124, 70);
  --window-orange: rgba(255, 225, 190, 0.5);
  --window-orange-opaque: hsl(32, 91%, 42%);
  --destructive-button-color: rgb(236, 100, 100);
  --img-load-blur-amount: 18px;
  --page-min-width: 640px;
  --page-width: 80%;
  --page-max-width: 1000px; 
}

@media only screen and (max-width: 640px) {
  :root {
    --page-min-width: 320px;
    --page-width: 90%;
    --page-max-width: unset;
  }
}

body {
  background-color: var(--main-white);
  margin: 0;
  font-size: 14pt;
  font-family: 'adobe-caslon-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  transition: all 0.5s ease;
}

img {
  max-width: 100%;
}