article.wedding-party-card {
  
  display: block;
  margin-top: 30px;
  margin-bottom: 120px;
  font-size: 1.1rem;

  img {
    width: 250px;
    height: 250px;
    box-shadow: var(--main-grey) 0 6px 18px -6px;
    filter: blur(0px) brightness(1.1) contrast(0.9);

    &.lq {
      filter: blur(var(--img-load-blur-amount));
    }
  }
  
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  
  &.left {
    text-align: left;
    max-width: 875px;
    img { float: left; }
    .text-content { padding: 0 0 0 30px; }
  } 
  
  &.right {
    text-align: right;
    margin-left: auto;
    max-width: 875px;
    img { float: right; }
    .text-content { padding: 0 30px 0 0; }
  }

  .text-content {
    display: inline-block;
    width: calc(100% - 250px);
    margin: 0;
  }

  .flex-border-container {
    margin-top: 0;
    span {
      font-size: 21pt;
    }
  }

  @media screen and (max-width: 640px) {
    .text-content {
      width: 95%;
      margin: 18px auto;
      text-align: left;
      display: block;
    }
    
    &.left, &.right {
      img {float: none;}
      .text-content {
        padding: 0;
      }
    }

    img {
      display: block;
      margin: auto;
    }
  }
}