.stream {
  display: flex; 
  width: 100%;
  height: 65vh;
  max-height: 62vw;
  min-height: unset;
  flex-direction: column; 

  .videoContainer {
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
  }
}